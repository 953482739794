import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children:
      'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item l3si9ajas1c-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0 l3shlyc7qz-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children:
      'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/left_eye.png',
  },
  content: {
    className: 'banner0-content l3shmy54brd-editor_css',
    children: (
      <span>
        <span>
          <p>LetterChain for Better Trust</p>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button l3shnsskdlh-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>信链信科技</p>
                      <p>行业数字化建设先行者</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '产品与服务' },
      {
        name: 'content~l3shss1hwk',
        className: '',
        children: (
          <span>
            <p>三“新”产品，五“星”服务</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon l3sgmeu363l-editor_css',
              children:
                'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/new_infrastructure.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>新基建</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>云网存储、智能运算、数据上链、数字系统基础设施开发建设</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon l3shu422rx8-editor_css',
              children:
                'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/new_applications.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>新应用</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    对行业应用、场景满足、数据价值，基于元宇宙NFT等新技术创新探索
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon l3shvdcavaf-editor_css',
              children:
                'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/idea.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>新模式</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    开发、建设、运营、价值实现数字化场景进行全新模式定制化构建
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>每个一个成功的数字化案例都是IT与OT的深度协作、同频共振</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/blockchain_platform.jpg',
          },
          content: {
            children: (
              <span>
                <p>
                  某商业客户区块链上链应用平台建设，并投入实用，已产生商业价值数百万美金
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/new_NFT_project.jpg',
          },
          content: {
            children: (
              <span>
                <p>
                  某基于NFT的某行业数字藏品再设计、配套系统开发建设和发行，主体文化传播与科技价值的完美结合
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/agile_project.jpg',
          },
          content: {
            children: (
              <span>
                <p>
                  助力某行业龙头企业客户数字化转型，从数据、业务、应用、价值管理，定制开发敏捷作战系统，服务业务生产
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/manage_project.jpg',
          },
          content: {
            children: (
              <span>
                <p>
                  助力某行业机构，基于区块链构建综合管理平台，对进行人、事、物的全方位、智能化、人性化管理，成效显著
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>信链信提供专业的数字化服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>基于区块链、元宇宙等数字化基础</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>区块链技术研发</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    区块链、NFT、元宇宙等新兴技术进行持续探索迭代应用储备和赋能
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>场景应用-NFT数字藏品</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  基于区块链技术在游戏、数字藏品、虚拟社交空间的场景应用落地
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>全新数字平台</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>一站式数据业务应用协同平台的搭建和运营</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/logo.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>专业的数字化服务</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '#',
              children: (
                <span>
                  <span>
                    <span>
                      <p>数字藏品</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p>区块链</p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p>元宇宙</p>
                </span>
              ),
            },
            { name: 'link3', href: '#', children: '参考指南' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'FAQ' },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '资源' },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>数字藏品发布</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p>元宇宙构建</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2022 by LetterChain All Rights Reserved&nbsp; &nbsp;
        {/* <img class="manimg" src="https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/beian.png"></img>  */}
		 	  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004998" rel="nofollow"><img src="https://website-1251030455.cos.ap-shanghai.myqcloud.com/website-images/beian.png"/>川公网安备 51019002004998号</a>
        <a target="_blank" href="http://beian.miit.gov.cn/">
          {' '}&nbsp;&nbsp;&nbsp;&nbsp;
        </a><a target="_blank" href="http://beian.miit.gov.cn/">
          蜀ICP备18030792号-1&nbsp;
        </a>
        <br />
      </span>
    ),
  },
};
